<template>
    <v-container fluid>
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">Nombre</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-text-field label="" v-model="group.name" required outlined dense></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row v-for="(group_team, index) in group.group_teams" :key="index" >
            <v-col cols="6">
                <v-row>
                    <v-col cols="3">
                        <v-subheader class="d-flex justify-end">País {{index+1}}</v-subheader>
                    </v-col>
                    <v-col cols="9">
                        <v-select v-model="group_team.team_id" :items="selectTeams" item-value="id" item-text="name"
                            label="" outlined dense />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    name: "form-group",
    props: ["group"],
    watch: {},
    data: () => ({
        maxVal : 4,
    }),
    computed: {
        ...mapState({
            selectTeams: (state) => state.team.selectTeams,
        }),
    },
    methods: {
        ...mapActions(["fetchAllTeams"]),
        getFetch() {
            this.fetchAllTeams();
        },
        refactorTeams(group_teams){
             if (group_teams.length < this.maxVal) {
                const lnM = group_teams.length
                for (let i = 0; i < this.maxVal - lnM; i++) {
                    group_teams.push({
                        key: i,
                        team_id : null,
                    });
                }
            }
        }
    },
    created() {
        this.getFetch();
        this.refactorTeams(this.group.group_teams);
    },
};
</script>

<style scoped>

</style>